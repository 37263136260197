
import Vue from 'vue'
import { mapActions } from 'vuex'
import ProductCardY from '../../common/widgets/ProductCardY.vue'
import Confirm from '../../common/dialogs/Confirm.vue'
import lodash from 'lodash'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RecentlyProduct',
  components: { ProductCardY, Confirm },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    flag: false,
  }),
  methods: {
    ...mapActions('RecentlyStore', [
      'fetchRecentlyProduct',
      'fetchRecentlyStoreScrollLocation',
    ]),
    // TODO
    onDeleteProduct(index: number) {
      this.$tracking(`recentview_${this.$numbering(index + 1)}_delete`)
      const items = JSON.parse(localStorage.getItem('recentProducts') ?? '[]')
      if (items.length < 0) {
        return
      }
      items.splice(index, 1)
      if (items.length) {
        localStorage.setItem(
          'recentProducts',
          JSON.stringify(lodash.uniq(items))
        )
      } else {
        localStorage.removeItem('recentProducts')
      }
      this.fetchRecentlyProduct()
    },
    onAllDeleteProduct() {
      localStorage.removeItem('recentProducts')
      this.fetchRecentlyProduct()
      this.dialog = false
    },
    onDialog() {
      this.$tracking('recentview_deleteall')
      this.dialog = !this.dialog
    },
    handleMallClick() {
      // 상품몰 클릭해서 StoreHome으로 이동시에는 뒤로가기시 스크롤 리포지션을 한다.
      this.fetchRecentlyStoreScrollLocation(this.$getAppHtml.scrollTop)
    },
  },
})

interface Data {
  dialog: boolean
  flag: boolean
}
interface Methods {
  onDeleteProduct(index: number): void
  onAllDeleteProduct(): void
  onDialog(): void
  fetchRecentlyProduct(): void
  handleMallClick(): void
  fetchRecentlyStoreScrollLocation(scroll: number): void
}
interface Computed {}
interface Props {
  items: ProductItem[]
}
