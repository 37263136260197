
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Slider from '../../common/slider/Slider.vue'

export default Vue.extend({
  name: 'RecentlyProduct',
  components: { Slider },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('RecentlyStore', ['recentlyProduct']),
  },
  methods: {
    ...mapActions('RecentlyStore', ['fetchRecentlyProduct']),
  },
})
// interface Data {}
// interface Methods {}
// interface Computed {}
// interface Props {
//   items: Array<ProductItem>
// }
