
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import RecentlyProductList from '../../views/recentlyProduct/RecentlyProductList.vue'
import RecentlyBestSeller from '../../views/recentlyProduct/RecentlyBestSeller.vue'
import RecentlyNotThing from '../../views/recentlyProduct/RecentlyNotThing.vue'

import DeviceDetector from 'device-detector-js'
import { onRequestAdonBanner } from '@/helper/banner'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RecentlyProduct',
  components: {
    RecentlyProductList,
    RecentlyBestSeller,
    RecentlyNotThing,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    categoryItem: [],
    userAgent: null,
  }),
  computed: {
    ...mapState('LoadingStore', ['loading']),
    ...mapState('RecentlyStore', [
      'recentlyProduct',
      'bestSeller',
      'loginedUserProduct',
    ]),
    ...mapState('UserStore', ['user']),
    ...mapState('BannerStore', ['onBanner']),
  },
  watch: {
    isLogined: {
      immediate: true,
      handler() {
        this.handleRefresh()
      },
    },
  },
  created() {
    this.fetchOnBanner({ onBanner: true })
  },
  mounted() {
    const deviceDetector = new DeviceDetector()
    const userAgent = navigator.userAgent
    this.userAgent = deviceDetector.parse(userAgent)
  },
  methods: {
    ...mapActions('RecentlyStore', ['fetchBestSeller', 'fetchRecentlyProduct']),
    ...mapActions('BannerStore', ['fetchOnBanner']),
    moveToStore() {
      this.$router.push('/store')
    },
    handleRefresh() {
      this.fetchBestSeller()
      this.fetchRecentlyProduct()
      if (this.$route.name) onRequestAdonBanner(this.$route.name)
    },
  },
})

interface Data {
  categoryItem: ProductItem[]
  userAgent: any
}

interface Methods {
  moveToStore(): void
  fetchBestSeller: () => Promise<void>
  fetchRecentlyProduct(): void
  handleRefresh(): void
  fetchOnBanner(payload: { onBanner: boolean }): Promise<void>
}

interface Props {
  items: Array<RecentProduct>
}
interface Computed {
  onBanner: boolean
}
interface RecentProduct {
  image: string
  title: string
  store: string
  price: string
  rate: string
  likeCount: string
  viewCount: string
  color: string
}
